import * as React from 'react';
import {
  Icon,
  InputGroup,
  InputLeftElement,
  FormControl,
  Input,
  InputProps,
  Textarea,
  BoxProps,
} from '@chakra-ui/core';
import { Field, FieldProps } from 'formik';
import { FormErrorMessage } from './FormErrorMessage';

const stringToPrice = (value: string) => {
  const stringValue = parseInt(value.replace('.', ''), 10);
  const dollars = Number(stringValue) / 100;

  return dollars.toFixed(2);
};

const stringToPhone = (value: string): string => {
  const cleaned = value.replace(/[^\d]/g, '');

  switch (cleaned.length) {
    case 1:
      return cleaned.replace(/^(\d{1})$/g, '($1');
    case 2:
      return cleaned.replace(/^(\d{2})$/g, '($1');
    case 3:
      return cleaned.replace(/^(\d{3})$/g, '($1');
    case 4:
      return cleaned.replace(/^(\d{3})(\d{1})$/g, '($1) $2');
    case 5:
      return cleaned.replace(/^(\d{3})(\d{2})$/g, '($1) $2');
    case 6:
      return cleaned.replace(/^(\d{3})(\d{3})$/g, '($1) $2');
    case 7:
      return cleaned.replace(/^(\d{3})(\d{3})(\d{1})$/g, '($1) $2-$3');
    case 8:
      return cleaned.replace(/^(\d{3})(\d{3})(\d{2})$/g, '($1) $2-$3');
    case 9:
      return cleaned.replace(/^(\d{3})(\d{3})(\d{3})$/g, '($1) $2-$3');
    case 10:
      return cleaned.replace(/^(\d{3})(\d{3})(\d{4})$/g, '($1) $2-$3');
    default:
      return cleaned
        .substr(0, 10)
        .replace(/^(\d{3})(\d{3})(\d{4})$/g, '($1) $2-$3');
  }
};

const stringToZipCode = (value: string): string => {
  return value.replace(/[^\d]/g, '').substr(0, 5);
};

export interface StyledFieldPropsInterface extends InputProps {
  containerProps?: BoxProps;
  errorMessageProps?: BoxProps;
}

export const StyledField = ({
  name,
  containerProps,
  errorMessageProps,
  type,
  placeholder,
  ...props
}: StyledFieldPropsInterface) => (
  <Field name={name}>
    {({
      field: { onChange, ...field },
      form: { setFieldValue, errors, touched },
    }: FieldProps) => {
      if (!name) return null;

      return (
        <FormControl
          isInvalid={Boolean(errors[name] && touched[name])}
          width="100%"
          mb="5px"
          {...containerProps}
        >
          {type === 'tel' && (
            <InputGroup width={['100%']}>
              <InputLeftElement>
                <Icon name="phone" color="gray.300" />
              </InputLeftElement>

              <Input
                type={type}
                placeholder={placeholder || 'Phone number'}
                name={name}
                onChange={e => {
                  if (name) {
                    setFieldValue(name, stringToPhone(e.target.value));
                  }
                }}
                {...field}
                {...props}
              />
            </InputGroup>
          )}

          {type === 'price' && (
            <InputGroup>
              <InputLeftElement color="gray.300" fontSize="1.2em">
                $
              </InputLeftElement>

              <Input
                type="number"
                placeholder={placeholder || 'Price'}
                name={name}
                onChange={e => {
                  if (name) {
                    setFieldValue(name, stringToPrice(e.target.value));
                  }
                }}
                {...field}
                {...props}
              />
            </InputGroup>
          )}

          {type === 'zip' && (
            <Input
              type={type}
              placeholder={placeholder || 'Zip code'}
              name={name}
              onChange={e => {
                if (name) {
                  setFieldValue(name, stringToZipCode(e.target.value));
                }
              }}
              {...field}
              {...props}
            />
          )}

          {type === 'textarea' && (
            <Textarea
              type={type}
              name={name}
              bg="base"
              resize="none"
              border="1px solid"
              borderColor="lightGray"
              placeholder={placeholder}
              onChange={e => {
                if (name) {
                  setFieldValue(name, e.target.value);
                }
              }}
              {...field}
              {...props}
            />
          )}

          {type !== 'tel' &&
            type !== 'zip' &&
            type !== 'price' &&
            type !== 'textarea' && (
              <Input
                type={type}
                placeholder={placeholder}
                onChange={e => {
                  if (name) {
                    setFieldValue(name, e.target.value);
                  }
                }}
                {...field}
                {...props}
              />
            )}

          <FormErrorMessage {...errorMessageProps}>
            {errors[name]}
          </FormErrorMessage>
        </FormControl>
      );
    }}
  </Field>
);
