import React, { useEffect, useState } from 'react';
import { useIntersection } from 'react-use';
import { BoxProps, Flex } from '@chakra-ui/core';

export const IntersectionContext = React.createContext({ inView: true });

export const useIntersectionObserverContext = () =>
  React.useContext(IntersectionContext);

interface IntersectionObserverProvider extends BoxProps {
  reset: boolean;
}

export const IntersectionObserverProvider: React.FC<IntersectionObserverProvider> = ({
  children,
  reset = false, // if value set to true - observed element will reappear every time it shows up on the screen
  ...props
}) => {
  const [inView, setInView] = useState(false);
  const intersectionRef = React.useRef(null);
  const intersection = useIntersection(intersectionRef, {
    threshold: 0,
  });

  // eslint-disable-next-line
  useEffect(() => {
    const inViewNow = intersection && intersection.intersectionRatio > 0;
    if (inViewNow) {
      return setInView(inViewNow);
    }
    if (reset) {
      return setInView(false);
    }
  }, [intersection, reset]);

  return (
    <IntersectionContext.Provider value={{ inView }}>
      <Flex ref={intersectionRef} {...props}>
        {children}
      </Flex>
    </IntersectionContext.Provider>
  );
};
