import * as React from 'react';
import {
  Text as BaseText,
  BoxProps,
  Box,
  Link,
  useDisclosure,
  Button,
} from '@chakra-ui/core';
import { PivotModal } from '../../PivotModal';

const Text = ({ children, ...props }: BoxProps) => (
  <BaseText color="red" textAlign="left" fontFamily="body" {...props}>
    {children}
  </BaseText>
);

export const getFaqContent = () => [
  {
    question: `UPCOMING CHANGES TO PRODUCT OFFERING`,
    answer: function answer() {
      const { isOpen, onOpen, onClose } = useDisclosure();

      return (
        <>
          <PivotModal isOpen={isOpen} onClose={onClose} />
          <Button
            variantColor="blue"
            bg="blue.400"
            color="white"
            onClick={() => onOpen()}
          >
            {' '}
            click me
          </Button>
        </>
      );
    },
  },
  {
    question: `How do I reheat my family meals?`,
    answer: function answer() {
      return (
        <Text>
          Our meals come in aluminum containers that are oven safe or you can
          heat up individual portions in the microwave. We will indicate which
          meals we highly recommend heating up in the microwave to retain the
          proper texture and moisture level.
        </Text>
      );
    },
  },
  {
    question: `How long do I heat my meal?`,
    answer: function answer() {
      return (
        <Text>
          As all microwaves work and reheat differently, we recommend 30-45
          second increments when reheating to ensure optimal temperature is
          reached without overcooking.
        </Text>
      );
    },
  },
  {
    question: `How do I pay?`,
    answer: function answer() {
      return (
        <Box>
          <Text>We accept payment via:</Text>
          <Box pl={4} pt={4}>
            <Link
              href="https://venmo.com/code?user_id=1686469039095809018"
              display="block"
              fontWeight="bold"
            >
              Venmo - @ShrutiP88
            </Link>
            <Link
              href="https://www.paypal.me/ShrutiPatel"
              display="block"
              fontWeight="bold"
            >
              PayPal - PayPal.me/shrutipatel
            </Link>
            <Text display="block" fontWeight="bold">
              Zelle - Shruti.p88@gmail.com
            </Text>
          </Box>
        </Box>
      );
    },
  },
  {
    question: `Is there a minimum number of meals I have to order?`,
    answer: function answer() {
      return (
        <Text>
          <Text fontWeight="bold" display="inline">
            Minimum 1 family meal a week or purchase of equivalent value ($45)
          </Text>
          , there is of course no cap, feel free to pick and choose the amount
          that works for you!
        </Text>
      );
    },
  },
  {
    question: `When do new menus come out?`,
    answer: function answer() {
      return (
        <Text>
          New menus are published on the app on
          {` `}
          <Text fontWeight="bold" display="inline">
            Wednesdays at 3pm
          </Text>
          , as well as posted on our instagram
          {` `}
          <Link href="https://instagram.com/mamasgotchu_" fontWeight="bold">
            @MamasGotchu_
          </Link>
          .
        </Text>
      );
    },
  },
  {
    question: `Where does Mama's Gotchu deliver to?`,
    answer: function answer() {
      return (
        <Text>
          At this time we are delivering throughout the city of Los Angeles,
          Orange County, and the Inland Empire. Deliveries within 20 miles of
          our kitchen will have a $10 delivery fee, anything more will be an
          additional $5-$10 pending distance.
        </Text>
      );
    },
  },
  {
    question: `How often are meals delivered?`,
    answer: function answer() {
      return (
        <Text>
          Meals are delivered
          {` `}
          <Text fontWeight="bold" display="inline">
            twice a week
          </Text>
          . An eta will be sent once meals leave the kitchen and are en route.
        </Text>
      );
    },
  },
  {
    question: `Can meals be delivered with little to no contact?`,
    answer: function answer() {
      return (
        <Text>
          We are offering no contact deliveries, our driver will be happy to
          send you a text or call once the meals are on your doorstep or
          designated place that was agreed upon.
        </Text>
      );
    },
  },
  {
    question: `Who do I contact if I have feedback about my delivery?`,
    answer: function answer() {
      return (
        <Text>
          Please call customer service at
          {` `}
          <Link href="tel:+13236098526" fontWeight="bold">
            (323) 609-8526
          </Link>
          {` `}
          or email us at
          {` `}
          <Link href="mailto:mamasgotchu@drizzle.catering" fontWeight="bold">
            mamasgotchu@drizzle.catering
          </Link>
          {` `}
          with any comments or concerns.
        </Text>
      );
    },
  },
];
