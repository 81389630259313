import * as React from 'react';
import { Flex, Box, Heading, Text } from '@chakra-ui/core';
import bgImage from '../../images/howItWorks/bg.jpg';

const steps = [
  {
    heading: '1. Pick your meal',
    justify: ['center', 'center', 'center', 'flex-start'],
    body: 'Weekly rotating meals with unique flavor profiles',
  },
  {
    heading: '2. We prep your meals',
    justify: ['center'],
    body: 'Sustainably sourced local proteins and farm fresh produce',
  },
  {
    heading: '3. Deliver and enjoy',
    justify: ['center', 'center', 'center', 'flex-end'],
    body: 'Delivered fresh and never frozen',
  },
];

interface StepProps {
  heading: string;
  justify: string[];
  body: string;
}

const Step = ({ heading, body, justify }: StepProps) => {
  return (
    <Flex width={['100%']} justifyContent={justify} pb={[4, 0]}>
      <Flex
        shadow="md"
        rounded="50%"
        border="3px solid"
        borderColor="white"
        alignItems={['center']}
        justifyContent={['center']}
        width={[250]}
        height={250}
        p={8}
        flexDirection={['column']}
        bg="blue.500"
      >
        <Heading size="md" textAlign="center" color="yellow.100">
          {heading}
        </Heading>
        <Text textAlign="center" mt={4}>
          {body}
        </Text>
      </Flex>
    </Flex>
  );
};

export const HowItWorks = () => {
  return (
    <Flex
      width={['100%']}
      id="how-it-works"
      borderBottom="3px solid"
      borderColor="white"
    >
      <Box minWidth={['100%', '100%', '100%', 500]} bg="yellow.300" p={8}>
        <Heading textAlign="center">How it works</Heading>
        <Flex flex={1} flexDirection={['column']} mt={8}>
          {steps.map(step => (
            <Step key={step.heading} {...step} />
          ))}
        </Flex>
      </Box>

      <Flex
        flex={1}
        display={['none', 'none', 'none', 'flex']}
        bg="pink.200"
        backgroundImage={`url(${bgImage})`}
        backgroundSize="cover"
        pt={[
          'calc(350 / 640  * 100%)',
          'calc(350 / 640  * 100%)',
          'calc(510 / 1024  * 100%)',
          'calc(1280 / 1920  * 70%)',
        ]}
      />
    </Flex>
  );
};
