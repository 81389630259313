import * as React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  AccordionIcon,
  Flex,
  Heading,
} from '@chakra-ui/core';
import { getFaqContent } from './FaqContent';
import bg from './assets/bg.jpg';

export const Faq = () => {
  return (
    <Flex
      width={['100%']}
      id="faq"
      alignItems={['center']}
      flexDirection={['column']}
      py={[8, 16, 20]}
      px={[8, 12, 12]}
      backgroundImage={[`url(${bg})`]}
      backgroundSize={['cover']}
    >
      <Accordion
        maxWidth={['100%', 960]}
        width={['100%']}
        rounded="lg"
        bg="pink.500"
        p={[4, 12, 16]}
        border="3px solid"
        borderColor="rgba(255,255,255,0.8)"
        shadow="md"
      >
        <Flex width={['100%']} justifyContent={['center']} mb={8}>
          <Heading>Faq</Heading>
        </Flex>
        {getFaqContent().map(faq => (
          <AccordionItem key={faq.question} width={['100%']}>
            <AccordionHeader>
              <Flex flex={1}>
                <Heading
                  textAlign="left"
                  fontSize={['md', 'lg', '2xl']}
                  fontFamily="body"
                  color="yellow.100"
                >
                  {faq.question}
                </Heading>
              </Flex>
              <AccordionIcon color="yellow.100" />
            </AccordionHeader>
            <AccordionPanel width={['100%']}>{faq.answer()}</AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Flex>
  );
};
