import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import { Hero } from '../components/Home/Hero';
import { HowItWorks } from '../components/Home/HowItWorks';
import { MeetTheChefs } from '../components/Home/MeetTheChefs';
import { Faq } from '../components/Home/Faq';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    <HowItWorks />
    <MeetTheChefs />
    <Faq />
  </Layout>
);

export default IndexPage;
