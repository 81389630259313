import React from 'react';
import { FormErrorMessage as ErrorMessage, BoxProps } from '@chakra-ui/core';

export const FormErrorMessage = ({ children, ...props }: BoxProps) => {
  return (
    <ErrorMessage
      bg="red.50"
      color="white"
      p={1}
      rounded="sm"
      px={3}
      {...props}
    >
      {children}
    </ErrorMessage>
  );
};
