import * as React from 'react';
import { Flex } from '@chakra-ui/core';
import desktopHero from '../../../images/hero/hero-desktop.jpg';
import { Blurb } from '../Blurb';
import { SignUpForm } from './SignUpForm';

export const Hero: React.FC<{}> = () => {
  return (
    <>
      <Flex
        width={['100%']}
        bg="beige"
        justifyItems={['flex-start', 'flex-start', 'flex-start', 'center']}
        flexDirection={['column']}
        alignItems={['center']}
        pt={[
          `calc(1280 / 1920 * 180%)`,
          `calc(1280 / 1920 * 100%)`,
          `calc(1280 / 1920 * 85%)`,
        ]}
        pb={[0, 0]}
        backgroundSize={['cover', 'cover', 'cover', 'cover']}
        backgroundRepeat="no-repeat"
        backgroundImage={[`url(${desktopHero})`]}
        backgroundPosition={['55%']}
        position="relative"
      >
        <Blurb />
      </Flex>
      <SignUpForm />
    </>
  );
};
