import * as React from 'react';
import { useWindowSize } from 'react-use';
import { Flex, Text, Image, BoxProps, Heading } from '@chakra-ui/core';
import { Parallax } from 'react-scroll-parallax';
import shruti from '../../images/shruti.png';
import wendy from '../../images/wendy.png';
import jose from '../../images/jose.png';
import desktopImage from '../../images/meetTheChefs/bg1.jpg';
import {
  IntersectionObserverProvider,
  useIntersectionObserverContext,
} from '../IntersectionObserver.Provider';

const chefs = [
  {
    name: 'Chef Shruti Patel',
    paragraphs: [
      `Chef Shruti Patel grew up in an eclectic food environment, with a strong footing in traditional Indian cuisine, peppered with Spanish influences. From her earliest memories of picking fresh produce to hosting dinner parties for friends and family, food has been the focal point of her upbringing and heritage.`,
      `After culinary school, Shruti got her start in catering with companies like Patina. She ran the kitchen at Tres LA boutique catering and worked under award-winning chefs at Carmelized, the Animal Restaurant Group’s catering arm. In 2017, she launched her own catering ventures, designed to give back and share her love of great food and exceptional experiences.`,
    ],
    imagePosition: 'right',
    imageUrl: shruti,
    containerProps: {
      bg: 'coral',
      ml: 0,
      borderColor: ['white'],
    },
    fadeDelayTime: 1,
  },
  {
    name: 'Chef Wendy Zeng',
    paragraphs: [
      `Born in the city of Chengdu, China, chef Wendy Zeng spent her formative years training in her grandfather’s kitchen. Immersed in the flavors and techniques of the Sichuan Province, Wendy mastered the intricacies of the region’s diverse cuisine. At the age of 10, she moved to Memphis, Tennessee, where she discovered Southern BBQ and soul food. She realized that, similar to Sichuan cuisine, Southern food is about family, history, and the dishes that bring us together.`,
      `Her culinary style weaves together the flavors and techniques of her familial roots with LA’s local influences. As the host of thematic pop-ups, she brings the values of her childhood to every table, fostering community among food lovers.`,
    ],
    imagePosition: 'top',
    imageUrl: wendy,
    containerProps: {
      bg: 'pink.600',
      mt: [0, 0, 0, 5],
      ml: [0, 0, 0, '10rem'],
    },
    fadeDelayTime: 2,
  },
];

interface ChefProfilePropsInterface {
  name: string;
  paragraphs: string[];
  imagePosition: string;
  containerProps: BoxProps;
  imageUrl: string;
  fadeDelayTime: number;
}

interface ImagePositionMapInterface {
  [key: string]: {
    [key: string]: number[];
  };
}

const imagePositionMap: ImagePositionMapInterface = {
  left: {
    left: [0, 0, 0, -230],
    top: [0, 0, 0, 0],
  },
  right: {
    right: [0, 0, 0, -220],
    top: [0, 0, 0, -30],
  },
  top: {
    right: [-0],
    top: [0, 0, 0, -200],
  },
};

const ChefProfileWrapper: React.FC<any> = ({ chef }) => {
  return (
    <IntersectionObserverProvider
      reset
      key={chef.name}
      position="relative"
      flexDirection={['row', 'row', 'row', 'column']}
      width={['100%', '100%', '100%', 0.3]}
      mb={[0, 0, 0, 0]}
      mx={[0, 0, 0, 20]}
      p={[4, 4, 10, 10]}
      py={[12, 4, 10]}
      rounded={['none', 'none', 'none', 'lg']}
      shadow={['none', 'none', 'none', 'md']}
      border={[0, 0, 0, '3px solid']}
      borderColor="rgba(255,255,255,0.8)"
      {...chef.containerProps}
    >
      <ChefProfile {...chef} />
    </IntersectionObserverProvider>
  );
};

const ChefProfile: React.FC<ChefProfilePropsInterface> = ({
  name,
  imageUrl,
  paragraphs,
  imagePosition = 'right',
  fadeDelayTime,
}) => {
  const { inView } = useIntersectionObserverContext();
  return (
    <Flex
      width={['100%']}
      opacity={inView ? 1 : 0}
      transition={`.5s ease-in-out opacity .${fadeDelayTime}s`}
      flexDirection={['column', 'row']}
      alignItems={['center', 'flex-start']}
    >
      <Image
        src={imageUrl}
        position={['initial', 'initial', 'initial', 'absolute']}
        borderRadius="50%"
        border={['3px solid']}
        shadow="md"
        borderColor="rgba(255,255,255,0.8)"
        width={[150, 150, 150, 250]}
        height={[150, 150, 150, 250]}
        mr={[5, 10, 10, 0]}
        ml={[2, 2, 10, 0]}
        {...imagePositionMap[imagePosition]}
      />
      <Flex
        flexDirection={['column']}
        width={['100%']}
        pr={[2, 2, 5, 0]}
        px={[8, 2]}
      >
        <Text
          color="white"
          fontFamily="heading"
          textAlign={['center', 'left']}
          fontSize={['xl', 'xl', 'xl', '2xl']}
          py={[4, 0]}
        >
          {name}
        </Text>
        {paragraphs.map((paragraph, pIndex) => (
          <Text
            key={paragraph}
            mt={pIndex === 0 ? 2 : [4, 8]}
            color="white"
            fontFamily="body"
            fontSize={['md']}
            lineHeight={[1.3]}
          >
            {paragraph}
          </Text>
        ))}
      </Flex>
    </Flex>
  );
};

export const MeetTheChefs = () => {
  const { width } = useWindowSize();

  return (
    <Flex
      width={['100%']}
      p={[0, 0, 0, 20]}
      flexDirection={['column']}
      id="about"
      backgroundImage={[`url(${desktopImage})`]}
      pt={[
        'calc(1280 / 1920 * 50%)',
        'calc(1280 / 1920 * 30%)',
        'calc(1280 / 1920 * 30%)',
      ]}
      pb={[0, 0, 0, 200]}
      backgroundSize={['100%', '100%', '100%', 'cover']}
      backgroundPosition={['top center', 'top center', 'top center', 'center']}
      minHeight={['100vh']}
      borderBottom={[0, 0, 0, '3px solid']}
      borderColor="white"
      backgroundRepeat="no-repeat"
      position="relative"
    >
      <Heading
        textAlign={['center', 'center', 'center', 'left']}
        size="xl"
        color="white"
        fontFamily="heading"
        textTransform="uppercase"
        whiteSpace="nowrap"
        mt={[-24, -20, 0, 0]}
        position={['static']}
      >
        Meet the chefs
      </Heading>
      <Parallax y={[-10, 15]} disabled={width < 1000}>
        <Flex
          width={['100%']}
          flexDirection={['column', 'column', 'column', 'row']}
          flexWrap={['nowrap', 'nowrap', 'nowrap', 'wrap']}
          mt={[10, 20, 40, 100]}
          alignItems={['center']}
          justifyContent={['center']}
        >
          {chefs.map((chef, index) => (
            <ChefProfileWrapper chef={chef} key={chef.name} />
          ))}
        </Flex>
      </Parallax>
    </Flex>
  );
};
