import * as React from 'react';
import { Flex, Text } from '@chakra-ui/core';

export const Blurb: React.FC<{}> = () => {
  return (
    <Flex
      width={['100%']}
      p={[2, 2, 4, 8]}
      bg="rgba(255,255,255, 0.7)"
      alignItems={['center']}
      justifyContent={['center']}
      position={['static', 'static', 'absolute']}
      bottom={[0, 0, 0]}
      borderBottom="3px solid"
      borderColor="white"
    >
      <Text
        color="blue.400"
        maxWidth={['100%', '100%', '100%', '60%']}
        lineHeight={1.3}
        fontFamily="body"
        fontWeight={900}
        textAlign="center"
        px={[8, 0]}
        fontSize={['sm', 'md', 'lg', '2xl']}
      >
        Welcome to Mama’s Gotchu, where mama’s chefs are curating meals to fit
        the healthy lifestyle you live by. Our ready-to-heat meals are packed
        with locally sourced ingredients that are never frozen, and unique
        flavor profiles to keep you fueled and on-the-go.
      </Text>
    </Flex>
  );
};
