import * as React from 'react';
import * as Yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Flex, Heading, Box, Button, BoxProps, Text } from '@chakra-ui/core';
import { Formik, Form } from 'formik';
import { StyledField } from '../../StyledField';

interface CTAPayloadInterface {
  phone: string;
  zip: string;
}

export const VERIFY_USER = gql`
  mutation verifyUser($phone: String!, $zipCode: String!) {
    verifyUser(phone: $phone, zipCode: $zipCode) {
      id
    }
  }
`;

export const ErrorMessage = ({ children, ...props }: BoxProps) => (
  <Text
    px="5px"
    py="10px"
    color="white"
    fontSize="sm"
    bg="red.50"
    textAlign="center"
    rounded="sm"
    {...props}
  >
    {children}
  </Text>
);

export const SignUpForm = (props: BoxProps) => {
  const [errorMessage, setErrorMessage] = React.useState('');
  const [formData, setFormData] = React.useState<{
    phone: string;
    zip: string;
  }>();
  const [verifyUser] = useMutation(VERIFY_USER, {
    onCompleted() {
      if (formData) {
        window.location.href = `${process.env.GATSBY_APP_URL}/welcome?phone=${formData.phone}&zip=${formData.zip}`;
      }
    },
    onError(error) {
      if (error.graphQLErrors.length) {
        setErrorMessage(error.graphQLErrors[0].message);
      }
    },
  });

  const handleCTASubmit = (values: CTAPayloadInterface) => {
    setFormData(values);
    verifyUser({
      variables: {
        phone: values.phone,
        zipCode: values.zip,
      },
    });
  };
  return (
    <Box
      rounded={['none', 'none', 'none', 'lg']}
      position={['static', 'static', 'static', 'absolute']}
      borderBottom={['3px solid', '3px solid', '3px solid', '3px solid']}
      borderTop={[0, 0, 0, '3px solid']}
      borderLeft={[0, 0, 0, '3px solid']}
      borderRight={[0, 0, 0, '3px solid']}
      borderColor={['white', 'white', 'white', 'rgba(255,255,255,0.8)']}
      left={12}
      top="30%"
      bg="blue.500"
      p={[8]}
      shadow="md"
      {...props}
    >
      <Heading
        size="md"
        color="yellow.100"
        textAlign={['center', 'center', 'center', 'left']}
      >
        Let us inspire your everyday meal
      </Heading>
      <Formik
        initialValues={{
          phone: '',
          zip: '',
        }}
        validationSchema={Yup.object().shape({
          phone: Yup.string()
            .matches(/^\(\d{3}\)[\s]\d{3}[.-]?\d{4}$/, 'Invalid phone number')
            .required('Phone number is required'),
          zip: Yup.string()
            .required('Zip code is required')
            .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'Invalid zip code'),
        })}
        onSubmit={handleCTASubmit}
      >
        {({ handleSubmit, isValid, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Flex mt={8}>
              <Flex
                flexDirection={['column']}
                mb={[5, 5, 5, 0]}
                width={['100%']}
                alignItems={['center']}
              >
                <StyledField
                  name="phone"
                  type="tel"
                  placeholder="Phone"
                  color="white"
                  variant="flushed"
                  _placeholder={{
                    color: 'yellow.100',
                  }}
                  fontFamily="body"
                />
                <StyledField
                  name="zip"
                  mt={1}
                  _placeholder={{
                    color: 'yellow.100',
                  }}
                  placeholder="Zip code"
                  fontFamily="body"
                  variant="flushed"
                  color="white"
                />
                {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                <Button
                  fontSize="xl"
                  isDisabled={!isValid}
                  mt={8}
                  type="submit"
                  textAlign="center"
                  bg="pink.500"
                  variantColor="pink"
                  color="yellow.100"
                  size="lg"
                  px={4}
                  py={2}
                  rounded="lg"
                  fontFamily="heading"
                >
                  Get started
                </Button>
              </Flex>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
